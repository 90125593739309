import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import AuthService from "../../services/auth/AuthService";
import { InternalStorageListsService } from "../../services/internalStorage/services/InternalStorageListsService";
import {
  FileService,
  UpdateProfileRequest,
  User,
  UserService,
} from "../../services/openapi";

export type AuthStateType = {
  signedIn: boolean;
  user: User | null;
};

const initialState: AuthStateType = {
  signedIn: false,
  user: null,
};

export const checkSignIn = createAsyncThunk<boolean, void>(
  "auth/checkSignIn",
  async () => {
    try {
      return !!(await AuthService.getAccessToken());
    } catch (e) {
      console.log(e);
      return false;
    }
  },
);

export const getAndSaveUserProfile = createAsyncThunk<User | null>(
  "auth/getAndSaveUserProfile",
  async () => {
    try {
      const user = await AuthService.getAndSaveUserProfile();
      await InternalStorageListsService.transferAnonymousDataToLoggedInUserAccount();
      return user;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const loginByGoogle = createAsyncThunk<string | null, string>(
  "auth/loginByGoogle",
  async (googleCode) => {
    try {
      return await AuthService.loginByGoogle(googleCode);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const loginByEmail = createAsyncThunk<
  string | null,
  { email: string; password: string }
>("auth/loginByEmail", async ({ email, password }) => {
  try {
    return await AuthService.loginByEmail(email, password);
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const signUp = createAsyncThunk<
  string | null,
  { email: string; password: string }
>("auth/signUp", async ({ email, password }) => {
  try {
    return await AuthService.signUp(email, password);
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const logoutCleanup = createAsyncThunk<void>("auth/logoutCleanup", async () => {
  try {
    await AuthService.logoutCleanup();
  } catch (e) {
    console.log(e);
  }
});
export const updateProfile = createAsyncThunk<
  UpdateProfileRequest | null,
  UpdateProfileRequest
>("auth/updateProfile", async ({ name, profileUrl }) => {
  try {
    await UserService.postApiUsersProfile({
      name: name,
      profileUrl: profileUrl || "",
    });
    return { name, profileUrl };
  } catch (e) {
    console.log(e);
    return null;
  }
});
export const updateLocalProfilePic = (file: Blob) => async () => {
  try {
    const url = await FileService.postApiFilesUpload({
      file: file,
    });
    return url;
  } catch {
    return null;
  }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkSignIn.fulfilled, (state, action) => {
      state.signedIn = action.payload;
    });
    builder.addCase(getAndSaveUserProfile.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.user = action.payload;
      }
    });
    builder.addCase(loginByGoogle.fulfilled, (state, action) => {
      if (action.payload) {
        state.signedIn = true;
      }
    });
    builder.addCase(loginByEmail.fulfilled, (state, action) => {
      if (action.payload) {
        state.signedIn = true;
      }
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      if (action.payload) {
        state.signedIn = true;
      }
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      if (action.payload && state.user) {
        state.user.name = action.payload.name;
        state.user.profileUrl = action.payload.profileUrl;
      }
    });
  },
});
