import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef } from "react";

import config from "../../appConfig/config";
import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemInternalModel } from "../../services/internalStorage/models/ListItemInternalModel";
import { ListItemPromptInternalModel } from "../../services/internalStorage/models/ListItemPromptInternalModel";
import {
  fetchShowRegistrationLastShow,
  setShowRegistationLastShow,
} from "../../store/application/applicationSlice";
import { selectAuthState } from "../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { EmptyContent } from "../EmptyContent/EmptyContent";
import { ListItemButton } from "../ListItemButton/ListItemButton";
import "./ListItems.scss";
import { WarningRegistrationAlert } from "../WarningRegistationAlert/WarningRegistationAlert";

export interface ListItemsProps {
  groupedByCategoryListItems: ListItemInternalModel[][];
  completedListItems: ListItemInternalModel[];
  prompts: ListItemPromptInternalModel[];
  categories: ListItemCategoryInternalModel[];
  isListItemCreationOpened: boolean;
  onListItemComplete: (id: number) => void;
  onListItemUnComplete: (id: number) => void;
  onDeleteAllListItems: () => void;
  onEditListItemClick: (listItemId: number) => void;
  onSaveNewListItem: (listItemName: string) => void;
  onCancelListItemCreation: () => void;
}

export const ListItems = (props: ListItemsProps) => {
  const dispatch = useAppDispatch();
  const { signedIn } = useAppSelector(selectAuthState);
  const handleListItemClick = (id: number) => {
    props.onListItemComplete(id);
  };

  const handleCompletedListItemClick = (id: number) => {
    props.onListItemUnComplete(id);
  };

  const handleDeleteAllListItems = () => {
    props.onDeleteAllListItems();
  };

  const onShouldShow = async (interval: number) => {
    const lastShow = Number((await dispatch(fetchShowRegistrationLastShow())).payload);
    return !signedIn && Date.now() - lastShow > interval;
  };
  const onAfterShow = async () => {
    await dispatch(setShowRegistationLastShow(Date.now()));
  };
  const listItemButton = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (props.isListItemCreationOpened && listItemButton.current) {
      listItemButton.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.isListItemCreationOpened]);

  if (
    !props.groupedByCategoryListItems?.length &&
    !props.completedListItems?.length &&
    !props.isListItemCreationOpened
  ) {
    return (
      <EmptyContent subtitle="Добавьте необходимые позиции голосовым или ручным вводом" />
    );
  }

  return (
    <Stack className="list-items">
      <WarningRegistrationAlert
        delay={config.ShowRegistrationDelay}
        interval={config.ShowRegistrationInterval}
        onShouldShow={onShouldShow}
        onAfterShow={onAfterShow}
      />
      {props.groupedByCategoryListItems.map((groupOfListItems, index) => (
        <Box key={index}>
          {index !== 0 && <Divider className="list-items-groups-divider" />}
          <List>
            {groupOfListItems.map((listItem) => (
              <ListItem key={listItem.localId}>
                <ListItemButton
                  name={listItem.name}
                  localId={listItem.localId}
                  category={listItem.localCategory}
                  isChecked={false}
                  onClick={handleListItemClick}
                  onEditClick={props.onEditListItemClick}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
      {props.isListItemCreationOpened && (
        <div ref={listItemButton}>
          <ListItemButton
            prompts={props.prompts}
            isChecked={false}
            onSaveNewListItem={props.onSaveNewListItem}
            onCancelListItemCreation={props.onCancelListItemCreation}
          />
        </div>
      )}
      <Divider className="list-items-divider" />
      {props.completedListItems?.length !== 0 && (
        <Accordion disableGutters defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="list-items-completed-heading">
              Завершенные ({props.completedListItems.length})
            </Typography>
            <IconButton
              className="list-items-completed-delete"
              onClick={handleDeleteAllListItems}
            >
              <DeleteOutlinedIcon
                color="error"
                className="list-items-completed-delete-icon"
              />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {props.completedListItems.map((listItem) => (
                <ListItem key={listItem.localId}>
                  <ListItemButton
                    name={listItem.name}
                    localId={listItem.localId}
                    isChecked={true}
                    onClick={handleCompletedListItemClick}
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </Stack>
  );
};
